<template>
  <section>
    <b-row fluid>
      <b-col lg="12" cols="12" sm="12">
        <b-col cols="12">
          <b-card-actions
              class="cari-liste-card"
              ref="stok-listesi"
              @refresh="refreshStop('stok-listesi')"
          >
            <b-row class="mt-50">
              <div class="card-header-row" :style="{backgroundColor: $store.getters.COLORS_GET.cardTitle}">
                <b-col cols="12">
                  <h4 class="text-white d-inline-block card-title">Stok Listesi</h4>
                  <div v-if="width > 1100" class="d-flex float-right">
                    <b-button
                        variant="flat-primary"
                        class="text-white float-right bg-info bg-lighten-1 mr-50"
                        v-if="id != 0 && id != 'add'"
                        @click="showAll"
                        :style="{backgroundColor:$store.getters.COLORS_GET.cardTitleButton + '!important'}"
                    >
                      Tüm Stokları Göster
                    </b-button>
                    <b-button
                        variant="flat-primary"
                        class="text-white float-right bg-primary bg-lighten-2 card-right-button"
                        v-b-modal.new
                        @click="isNewStok = true"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        :style="{backgroundColor:$store.getters.COLORS_GET.cardTitleButton+'!important',border:'none'}"
                    >
                      Yeni Stok
                    </b-button>
                  </div>
                </b-col>
              </div>
            </b-row>
            <b-row v-if="width < 1100" class="mt-4">
              <b-col cols="12">
                <b-button
                    variant="flat-primary"
                    class="text-white float-right bg-primary bg-lighten-2 card-right-button"
                    v-b-modal.new
                    @click="isNewStok = true"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    :style="{backgroundColor:$store.getters.COLORS_GET.cardTitleButton+'!important',border:'none'}"
                >
                  Yeni Stok
                </b-button>
                <b-button
                    variant="flat-primary"
                    class="text-white float-right bg-info bg-lighten-1 mr-50"
                    v-if="id != 0 && id != 'add'"
                    @click="showAll"
                    :style="{backgroundColor:$store.getters.COLORS_GET.cardTitleButton + '!important'}"
                >
                  Tüm Stokları Göster
                </b-button>
              </b-col>
            </b-row>
            <div class="d-flex justify-content-between flex-wrap table-top">
              <b-form-group class="mr-1 mb-md-0 unPrint" size="sm">
                <b-input-group
                    size="sm"
                    prepend="Gösterilecek kayıt sayısı"
                    class="unPrint"
                >
                  <b-form-select
                      id="per-page-select"
                      v-model="perPage"
                      :options="pageOptions"
                      @change="perPageChange($event)"
                      size="sm"
                  >
                  </b-form-select>
                </b-input-group>
              </b-form-group>
              <b-input-group size="sm" class="search-bar" :style="{width : width < 768 ? '40%!important':''}">
                <b-input-group-prepend>
                  <b-button
                      variant="outline-primary"
                      size="sm"
                      disabled
                      class="search-input mb-0"
                      :style="{height : width < 768 ? '30px':''}"
                  >
                    Ara
                  </b-button>
                </b-input-group-prepend>
                <b-form-input id="filterInput" v-model="filter" type="search" autocomplete="off"/>
              </b-input-group>
            </div>
            <hr/>
            <b-table
                striped
                hover
                responsive
                class="position-relative cari-group-table mt-1"
                :per-page="perPage"
                :items="listGet"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
            >
              <template #cell(stokID)="data">
                <b-badge variant="primary" id="firma-unvan-badge" class="align-top">
                  #{{ data.item.stokID }}
                </b-badge>
                <p id="firma-unvan-yetkili">{{ data.item.adi }}</p>
              </template>
              <template #cell(stokAdi)="data">
                <strong class="ml-50">
                  {{ data.item.stokAdi }}
                </strong>
              </template>
              <template #cell(grupAdi)="data">
                <b-badge
                    id="bakiye-badge"
                    variant="light-primary"
                    size="sm"
                >
                  {{ data.item.grupAdi }}
                </b-badge>
              </template>
              <template #cell(barkod)="data">
                <strong class="ml-50">
                  <b-badge
                      id="bakiye-badge"
                      variant="primary"
                      v-if="data.item.barkod"
                  >
                    {{ data.item.barkod }}
                  </b-badge>
                </strong>
              </template>
              <template #cell(alisFiyat)="data">
                <strong class="ml-50">
                  <b-badge
                      id="bakiye-badge"
                      variant="primary"
                      v-if="data.item.alisFiyat"
                  >
                    {{ data.item.alisFiyat }}
                  </b-badge>
                </strong>
              </template>
              <template #cell(adet)="data">
                <strong class="ml-50">
                  <b-badge
                      id="bakiye-badge"
                      :variant="data.item.adet < 10 ? 'danger' : data.item.adet < 20 ? 'warning':'success'  "
                      v-if="data.item.adet"
                  >
                    {{ data.item.adet }}
                  </b-badge>
                </strong>
              </template>
              <template #cell(kdv)="data">
                <b-button
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    variant="flat-primary"
                    class="btn-icon rounded-circle"
                    @click="updateStepOne(data.item)"
                    v-b-modal.edit
                >
                  <font-awesome-icon icon="fa-solid fa-pen-to-square"/>
                </b-button>
                <b-button
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    variant="flat-danger"
                    class="btn-icon rounded-circle"
                    @click="itemDelete(data.item.stokID)"
                    v-if="$store.getters.getRole == 1"
                >
                  <font-awesome-icon icon="fa-solid fa-trash-can"/>
                </b-button>
              </template>
            </b-table>
            <hr/>
            <b-row class="d-flex align-items-center mt-1">
              <b-col cols="6">
                <p class="text-muted">Toplam {{ totalRows }} Kayıt bulundu</p>
              </b-col>
              <b-col cols="6">
                <b-pagination
                    v-model="currentPage"
                    @change="PageChange($event)"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    first-number
                    last-number
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mb-0 float-right"
                />
              </b-col>
            </b-row>
          </b-card-actions>
        </b-col>
      </b-col>
    </b-row>
    <b-modal
        id="new"
        ok-only
        hide-footer
        hide-header
        centered
        size="lg"
        header-bg-variant="white"
        no-close-on-esc
        no-close-on-backdrop
    >
      <b-row>
        <b-col cols="12">
          <h3>
            <b-badge variant="success" class="modalBadge"
                     :style="{backgroundColor:$store.getters.COLORS_GET.bgSuccess+'!important',border:'none'}"
            >
              Yeni Stok Kaydı
            </b-badge>
          </h3>
        </b-col>
        <b-col cols="6" class="mt-1">
          <b-form-group label="Stok Adı" label-for="basicInput">
            <b-form-input autocomplete="off" v-model="addItem.name" id="basicInput"/>
          </b-form-group>
        </b-col>
        <b-col cols="6" class="mt-1">
          <b-form-group label="Stok Grubu" label-for="basicInput">
            <model-list-select
                class=""
                v-model="addItem.grupID"
                :list="groupGet"
                option-value="stokGrupID"
                option-text="grupAdi"

                placeholder="Stok Grubunu Seçiniz"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3" class="mt-1">
          <b-form-group label="Alış Fiyat" label-for="basicInput">
            <b-form-input type="number" autocomplete="off" v-model="addItem.alisFiyat" id="basicInput"/>
          </b-form-group>
        </b-col>
        <b-col cols="3" class="mt-1">
          <b-form-group label="Birim Fiyatı" label-for="basicInput">
            <b-form-input type="number" autocomplete="off" v-model="addItem.birimFiyat" id="basicInput"/>
          </b-form-group>
        </b-col>
        <b-col cols="3" class="mt-1">
          <b-form-group label="KDV (%)" label-for="basicInput">
            <b-form-input autocomplete="off" v-model="addItem.kdv" id="basicInput"/>
          </b-form-group>
        </b-col>
        <b-col cols="3" class="mt-1">
          <b-form-group label="Birim" label-for="basicInput">
            <b-form-select v-model="addItem.birim" :options="birimOptions" placeholder="Stok Grubunu Seçiniz"/>
          </b-form-group>
        </b-col>
        <b-col cols="4" class="mt-1">
          <b-form-group label="Barkod" label-for="basicInput">
            <b-form-input autocomplete="off" v-model="addItem.barkod" id="basicInput"/>
          </b-form-group>
        </b-col>
        <b-col cols="3" class="mt-1">
          <b-form-group :label="addItem.birim" label-for="basicInput">
            <b-form-input autocomplete="off" v-model="addItem.adet" id="basicInput"/>
          </b-form-group>
        </b-col>
        <b-col cols="2" class="mt-1">
          <b-form-group id="stoksuz-satis-label" label="Stoksuz Satış" label-for="basicInput">
            <b-form-checkbox
                checked="true"
                class="custom-control-primary"
                name="check-button"
                switch
                autocomplete="off"
                v-model="addItem.stoksuzSatis"
            >
              <span class="switch-icon-left">
                <font-awesome-icon icon="fa-solid fa-check"/>
              </span>
              <span class="switch-icon-right">
                    <font-awesome-icon icon="fa-solid fa-xmark"/>
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col cols="12" class="d-flex justify-content-end">
          <b-button
              @click="add"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="mr-50"
              :style="{backgroundColor:$store.getters.COLORS_GET.bgInfo+'!important',border:'none'}"
              :disabled="!addItem.name || !addItem.grupID"
          >
            Kaydet
          </b-button>
          <b-button
              @click="newReset"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-warning"
              class="mr-50"
          >
            Sıfırla
          </b-button>
          <b-button
              @click="newHide"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-danger"
          >
            Kapat
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
        id="edit"
        ok-only
        hide-footer
        hide-header
        centered
        size="lg"
        header-bg-variant="white"
        no-close-on-esc
        no-close-on-backdrop
    >
      <b-row>
        <b-col cols="12">
          <h3 class="d-inline-block">
            <b-badge variant="success" class="modalBadge">
              {{ editName }}
            </b-badge>
          </h3>
          <h5 class="edit-name">Adlı Stok Düzenleniyor</h5>
        </b-col>
        <b-col cols="6" class="mt-1">
          <b-form-group label="Stok Adı" label-for="basicInput">
            <b-form-input autocomplete="off" v-model="editItem.stokAdi" id="basicInput"/>
          </b-form-group>
        </b-col>
        <b-col cols="6" class="mt-1">
          <b-form-group label="Stok Grubu" label-for="basicInput">
            <model-list-select
                v-model="editItem.stokGrupID"
                :list="groupGet"
                option-value="stokGrupID"
                option-text="grupAdi"
                placeholder="Stok Grubunu Seçiniz"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3" class="mt-1">
          <b-form-group label="Alış Fiyat" label-for="basicInput">
            <b-form-input type="number" autocomplete="off" v-model="editItem.alisFiyat" id="basicInput"/>
          </b-form-group>
        </b-col>
        <b-col cols="3" class="mt-1">
          <b-form-group label="Birim Fiyatı" label-for="basicInput">
            <b-form-input type="number" autocomplete="off" v-model="editItem.birimFiyat" id="basicInput"/>
          </b-form-group>
        </b-col>
        <b-col cols="3" class="mt-1">
          <b-form-group label="KDV" label-for="basicInput">
            <b-form-input autocomplete="off" v-model="editItem.kdv" id="basicInput"/>
          </b-form-group>
        </b-col>
        <b-col cols="3" class="mt-1">
          <b-form-group label="Birim" label-for="basicInput">
            <b-form-select v-model="editItem.birim" :options="birimOptions" placeholder="Stok Grubunu Seçiniz"/>
          </b-form-group>
        </b-col>
        <b-col cols="4" class="mt-1">
          <b-form-group label="Barkod" label-for="basicInput">
            <b-form-input autocomplete="off" v-model="editItem.barkod" id="basicInput"/>
          </b-form-group>
        </b-col>
        <b-col cols="3" class="mt-1">
          <b-form-group :label="editItem.birim" label-for="basicInput">
            <b-form-input autocomplete="off" v-model="editItem.adet" id="basicInput"/>
          </b-form-group>
        </b-col>
        <b-col cols="2" class="mt-1">
          <b-form-group id="stoksuz-satis-label" label="Stoksuz Satış" label-for="basicInput">
            <b-form-checkbox
                checked="true"
                class="custom-control-primary"
                name="check-button"
                switch
                autocomplete="off"
                v-model="editItem.stoksuzSatis"
            >
              <span class="switch-icon-left">
                <font-awesome-icon icon="fa-solid fa-check"/>
              </span>
              <span class="switch-icon-right">
                    <font-awesome-icon icon="fa-solid fa-xmark"/>
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col cols="12" class="d-flex justify-content-end">
          <b-button
              @click="update"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="mr-50"
              :style="{backgroundColor:$store.getters.COLORS_GET.bgInfo+'!important',border:'none'}"
          >
            Kaydet
          </b-button>
          <b-button
              @click="editHide"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-danger"
          >
            Vazgeç
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-sidebar
        id="add-stok-grup"
        :visible="isNewGroup"
        bg-variant="white"
        sidebar-class="sidebar-lg"
        shadow
        backdrop
        no-header
        :no-close-on-backdrop="true"
        :no-close-on-esc="true"
        right
        @change="(val) => $emit('update:is-add-stok-grup-active', val)"
        ref="newGrup"
    >
      <template>
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            Yeni Stok Grubu Ekle
          </h5>
          <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="newGroupCancel"
          />
        </div>
        <b-form @submit.prevent id="sidebar-form">
          <b-row>
            <b-col cols="12">
              <b-form-group
                  label="Grup Adı"
                  label-for="vi-first-name"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="UserIcon"/>
                  </b-input-group-prepend>
                  <b-form-input
                      id="vi-first-name"
                      v-model="grupName"
                      autocomplete="off"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mr-1"
                  :disabled="!grupName"
                  @click="grupSave('kayit')"
                  :style="{backgroundColor:$store.getters.COLORS_GET.bgInfo+'!important',border:'none'}"
              >
                <feather-icon
                    icon="SaveIcon"
                    class="mr-50"
                />
                Kaydet
              </b-button>
              <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
                  @click="newGroupCancel"
              >
                Vazgeç
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </template>
    </b-sidebar>
  </section>
</template>

<script>
import {
  BSidebar,
  BModal,
  BFormSelect,
  BInputGroupAppend,
  BPagination,
  VBTooltip,
  BBadge,
  BTable,
  BRow, BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BInputGroup,
  BInputGroupPrepend,
  BForm,
  BButton,
  BCardHeader,
  BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
import 'vue-search-select/dist/VueSearchSelect.css'
import { ModelListSelect } from 'vue-search-select/dist/VueSearchSelect.common'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import _ from 'lodash'
import axios from 'axios'
import router from '@/router'
import { useWindowSize } from '@vueuse/core'

export default {
  setup() {
    const {
      width,
    } = useWindowSize()
    return {
      width,
    }
  },
  components: {
    BSidebar,
    BModal,
    BFormSelect,
    BInputGroupAppend,
    BPagination,
    BBadge,
    BTable,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    BButton,
    BRow,
    BCol,
    BCardHeader,
    BCardActions,
    ModelListSelect,
  },
  data() {
    return {
      isNewStok: false,
      oldEditGrupID: '',
      grupChange: '',
      id: this.$route.params.id,
      isNewGroup: false,
      grupName: '',
      grupContent: '',
      birimOptions: ['Adet', 'Koli', 'Paket', 'Kutu', 'Kg', 'Gram', 'Litre', 'Ton', 'Net Ton', 'Gross Ton', 'Yıl', 'Saat', 'Dakika', 'Saniye', 'mm', 'cm', 'Hücre Adet', 'Cift', 'Set', 'Düzine', 'Brüt Kalori'],
      degismeyenData: [],
      editName: '',
      fields: [
        {
          key: 'stokID',
          label: 'STOK ID',
          sortable: true,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '6%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '6%' },
        },
        {
          key: 'stokAdi',
          label: 'STOK ADI',
          sortable: true,
          thStyle: {
            width: '33%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '33%' },
        },
        {
          key: 'grupAdi',
          label: 'STOK GRUBU',
          sortable: true,
          thStyle: {
            width: '30%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '30%' },
        },
        {
          key: 'barkod',
          label: 'BARKOD',
          sortable: true,
          thStyle: {
            width: '6%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '6%' },
        },
        {
          key: 'alisFiyat',
          label: 'ALIŞ / SATIŞ',
          sortable: true,
          thStyle: {
            width: '10%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '10%' },
        },
        {
          key: 'adet',
          label: 'ADET',
          sortable: true,
          thStyle: {
            width: '6%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '6%' },
        },
        {
          key: 'kdv',
          label: '',
          sortable: false,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '9%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '9%' },
        },
      ],
      perPage: 10,
      pageOptions: [5, 10, 20, 50, 100, 200, 500],
      toplamKayit: 1,
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      addItem: {
        name: '',
        grupID: '',
        adet: '',
        birim: 'Adet',
        birimFiyat: '',
        kdv: '',
        alisFiyat: '',
        stoksuzSatis: false,
        barkod: '',
      },
      editItem: {
        stokAdi: '',
        stokGrupID: '',
        adet: '',
        birim: '',
        birimFiyat: '',
        kdv: '',
        alisFiyat: '',
        stoksuzSatis: false,
        barkod: '',
      },
      updateStokID: '',
    }
  },
  computed: {
    listGet() {
      return Object.values(store.getters.STOK_LIST_GET)
    },
    groupDefaultGet() {
      return Object.values(store.getters.STOK_GROUP_GET)
    },
    groupGet() {
      let grupList = []
      grupList.push(Object.values(store.getters.STOK_GROUP_GET))
      grupList[0].push({
        grupAdi: 'Yeni Stok Grubu Oluştur',
        stokGrupID: '-1',
        yeni: true,
      })
      return _.orderBy(grupList[0], ['yeni', 'grupAdi'], ['asc', 'desc'])
    },
  },
  watch: {
    '$route.params.id': function (newVal) {
      store.dispatch('stokList', {
        uzunluk: this.perPage,
        baslangic: 0,
        stokGrupID: newVal,
      })
          .then(res => {
            this.toplamKayit = Object.values(this.$store.getters.STOK_LIST_GET).length
            this.totalRows = res.toplamKayit
          })
    },
    addItem: {
      deep: true,
      handler(newVal) {
        if (newVal.grupID == -1) {
          this.$root.$emit('bv::toggle::collapse', 'add-stok-grup')
          this.$bvModal.hide('new')
          this.isNewGroup = true
        }
      },
    },
    editItem: {
      deep: true,
      handler(newVal) {
        if (newVal.stokGrupID == -1) {
          this.$root.$emit('bv::toggle::collapse', 'add-stok-grup')
          this.$bvModal.hide('edit')
          this.isNewGroup = true
        }
      },
    },
    filter(newVal) {
      this.$refs['stok-listesi'].showLoading = true
      this.$store
          .dispatch('stokList', {
            uzunluk: this.perPage,
            baslangic: 0,
            searchKey: newVal,
          })
          .then(res => {
            this.$refs['stok-listesi'].showLoading = false
            this.toplamKayit = res.kayitlar.length
            this.totalRows = res.toplamKayit
          })
    },
  },
  methods: {
    update() {
      // const guncellenenKeyler = Object.entries(this.degismeyenData)
      //     .reduce((acc, [key, value]) => {
      //       if (['stokID', 'firmaKodu'].includes(key)) return acc
      //       if (this.editItem[key] && this.editItem[key] !== value) {
      //         acc[key] = this.editItem[key]
      //       }
      //       return acc
      //     }, {})
      const item = this.editItem
      const fd = new FormData()
      fd.append('crm_token', store.state.userToken)
      fd.append('serviceName', 'stok')
      fd.append('methodName', 'stokDuzenle')
      fd.append('islem', 'duzenle')
      fd.append('stokID', this.updateStokID)
      fd.append('updateList[stoksuzSatis]', item.stoksuzSatis == true ? 1 : 0)
      fd.append('updateList[adet]', item.adet || 0)
      fd.append('updateList[alisFiyat]', item.alisFiyat || 0)
      fd.append('updateList[barkod]', item.barkod || ' ')
      fd.append('updateList[birim]', item.birim || 'Adet')
      fd.append('updateList[birimFiyat]', item.birimFiyat || 0)
      fd.append('updateList[kdv]', item.kdv || 0)
      fd.append('updateList[stokAdi]', item.stokAdi || ' ')
      fd.append('updateList[stokGrupID]', item.stokGrupID || ' ')
      axios.post(store.state.POST_URL, fd, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
          .then((res, position = 'bottom-right') => {
            if (res.data.result.status == 200) {
              this.$bvModal.hide('edit')
              store.commit('setToken', res.data.userToken)
              const mesaj = this.$store.getters.notificationSettings(res.data)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                }, { position })
                store.dispatch('stokList', {
                  uzunluk: this.perPage,
                  baslangic: 0,
                  stokGrupID: this.id,
                  searchKey: this.filter,
                })
                    .then(res => this.totalRows = res.toplamKayit)
              }
            } else {
              const mesaj = this.$store.getters.notificationSettings(res.data)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err.data)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    updateStepOne(item) {
      this.oldEditGrupID = item.stokGrupID
      this.editName = item.stokAdi
      this.degismeyenData = []
      this.updateStokID = item.stokID
      this.degismeyenData = item
      this.editItem.stokGrupID = item.stokGrupID
      this.editItem.stokAdi = item.stokAdi
      this.editItem.adet = item.adet
      this.editItem.birim = item.birim
      this.editItem.birimFiyat = item.birimFiyat
      this.editItem.kdv = item.kdv
      this.editItem.alisFiyat = item.alisFiyat
      this.editItem.stoksuzSatis = item.stoksuzSatis == 1
      this.editItem.barkod = item.barkod
    },
    showAll() {
      this.$router.push({ path: '/stokListesi/0' })
      this.id = 0
    },
    grupSave(islem) {
      store.dispatch('stokGroupSave', {
        adi: islem === 'kayit' ? this.grupName : this.editItem.grupAdi,
        islem,
      })
          .then((res, position = 'bottom-right') => {
            if (res.result.status == 200) {
              this.addItem.grupID = res.data.stokGrupID
              this.editItem.stokGrupID = res.data.stokGrupID
              this.isNewStok ? this.$bvModal.show('new') : this.$bvModal.show('edit')
              this.$refs.newGrup.hide()
              this.grupName = ''
              this.grupContent = ''
              if (res.result.errMsg) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: res.result.errMsg,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                }, { position })
              }
            } else {
              const mesaj = store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          })
          .catch(err => {
            this.addItem.stokGrupID = ''
            const mesaj = store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    newGroupCancel() {
      this.grupName = ''
      if (this.isNewStok) {
        this.addItem.grupID = ''
        this.$bvModal.show('new')
      } else {
        this.editItem.stokGrupID = this.oldEditGrupID
        this.$bvModal.show('edit')
      }
      this.isNewGroup = false
    },
    add() {
      store.dispatch('stokSave', {
        stokAdi: this.addItem.name,
        stokGrupID: this.addItem.grupID,
        adet: this.addItem.adet,
        birim: this.addItem.birim,
        birimFiyat: this.addItem.birimFiyat.replace(',', '.'),
        kdv: this.addItem.kdv,
        alisFiyat: this.addItem.alisFiyat.replace(',', '.'),
        stoksuzSatis: this.addItem.stoksuzSatis,
        barkod: this.addItem.barkod,
      })
          .then((res, position = 'bottom-right') => {
            if (res.result.status == 200) {
              this.isNewStok = false
              this.newReset()
              this.$bvModal.hide('new')
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Başarılı',
                  text: res.result.errMsg,
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              }, { position })
            } else {
              const mesaj = store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          })
          .catch(err => {
            const mesaj = store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    newHide() {
      router.push({ path: '/stokListesi/0' })
      this.$bvModal.hide('new')
      const t = this
      t.isNewStok = false
      t.addItem.name = ''
      t.addItem.grupID = ''
      t.addItem.adet = ''
      t.addItem.birim = 'Adet'
      t.addItem.birimFiyat = ''
      t.addItem.kdv = ''
      t.addItem.alisFiyat = ''
      t.addItem.stoksuzSatis = false
      t.addItem.barkod = ''
    },
    editHide() {
      this.updateStokID = ''
      this.$bvModal.hide('edit')
      const t = this
      t.editName = ''
      t.editItem.stokAdi = ''
      t.editItem.stokGrupID = ''
      t.editItem.adet = ''
      t.editItem.birim = 'Adet'
      t.editItem.birimFiyat = ''
      t.editItem.kdv = ''
      t.editItem.alisFiyat = ''
      t.editItem.stoksuzSatis = false
      t.editItem.barkod = ''
    },
    newReset() {
      this.addItem.name = ''
      this.addItem.grupID = ''
      this.addItem.adet = ''
      this.addItem.birim = 'Adet'
      this.addItem.birimFiyat = ''
      this.addItem.kdv = ''
      this.addItem.alisFiyat = ''
      this.addItem.stoksuzSatis = false
      this.addItem.barkod = ''
    },
    perPageChange(newVal) {
      const veri = this.currentPage * newVal - newVal
      this.$refs['stok-listesi'].showLoading = true
      this.$store
          .dispatch('stokList', {
            uzunluk: newVal,
            baslangic: veri,
            searchKey: this.filter,
          })
          .then(res => {
            this.$refs['stok-listesi'].showLoading = false
            this.toplamKayit = res.kayitlar.length
            this.totalRows = res.toplamKayit
          })
    },
    PageChange(newVal) {
      const veri = newVal * this.perPage - this.perPage
      this.$refs['stok-listesi'].showLoading = true
      this.$store
          .dispatch('stokList', {
            uzunluk: this.perPage,
            baslangic: veri,
            searchKey: this.filter,
          })
          .then(res => {
            this.$refs['stok-listesi'].showLoading = false
            this.toplamKayit = res.kayitlar.length
            this.totalRows = res.toplamKayit
          })
    },
    itemDelete(id) {
      store.dispatch('stokDelete', id)
          .then((res, position = 'bottom-right') => {
            if (res.result.status == 200) {
              store.dispatch('stokList', {
                uzunluk: this.perPage,
                baslangic: 0,
                searchKey: this.filter,
              })
                  .then(res => {
                    this.totalRows = res.toplamKayit
                  })
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Başarılı',
                  text: res.result.errMsg,
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              }, { position })
            } else if (res.result.status == 303) {
              this.$swal({
                title: res.result.errMsg,
                text: res.helpMsg,
                icon: 'warning',
                confirmButtonText: 'Tamam',
                customClass: {
                  confirmButton: 'btn btn-primary',
                  cancelButton: 'btn btn-outline-danger ml-1',
                },
                showCloseButton: true,
                buttonsStyling: false,
              })
            } else {
              const mesaj = store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          })
          .catch(err => {
            const mesaj = store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    refreshStop(cardName) {
      setTimeout(() => {
        this.$refs[cardName || 'stok-listesi'].showLoading = false
      }, 1000)
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
  mounted() {
    if (this.id == 'add') {
      this.$bvModal.show('new')
    }
  },
  created() {
    store.dispatch('stokList', {
      uzunluk: this.perPage,
      baslangic: 0,
      stokGrupID: this.id == 'add' ? 0 : this.id,
    })
        .then(res => this.totalRows = res.toplamKayit)
    store.dispatch('stokGrup')
  },
  beforeDestroy() {
    store.commit('STOK_LIST_ACTION', 'remove')
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
}
</script>
<style>
.grup-select {
  display: inline-block !important;
  width: 15% !important;
  float: right !important;
  margin-right: 1% !important;
  margin-top: 0.3% !important;
  margin-bottom: 0.3% !important;
}

.b-sidebar .b-sidebar-body .content-sidebar-header {
  height: 4% !important;
}

.msl-searchable-list__items {
  border-top: none !important;
}

.cari-group-table .table thead th, table tbody td {
  padding: 0.4rem !important;
}

.cari-liste-card .card-header, .cari-group-add .card-header, .cari-group-edit .card-header {
  display: none !important;
}

.transfer-modal .msl-multi-select__list {
  width: 43% !important;
}
</style>
<style scoped>

.table-top {
  margin-top: 4.5% !important;
}

.card-title {
  transform: translateY(10%) !important;
}

.card-right-button {
  /*transform: translateY(2%) !important;*/
}

#sidebar-form {
  padding: 30px !important;
}

.edit-name {
  display: inline-block;
  margin-left: 2%;
}

.search-bar {
  width: 20% !important;
}

.transfer-modal ::-webkit-scrollbar {
  width: 7px !important;
}

.transfer-modal ::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

.transfer-modal ::-webkit-scrollbar-thumb {
  background: #888 !important;
}

.transfer-modal ::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}

.card-header-row {
  width: 102% !important;
  position: absolute;
  background-color: lightslategrey;
  margin: 0 -1.5% !important;
  border-radius: 6px;
}

.cari-group-edit .card-header-row {
  width: 102% !important;
  position: absolute;
  background-color: lightslategrey;
  margin: 0 -1.5% !important;
  border-radius: 6px;
  background-color: #F48484 !important;
}

.item-group-tur {
  position: absolute !important;
  margin-left: 3%;
}

.list-group-item {
  border: none !important;
  cursor: pointer !important;
  transition: 200ms;
}

.list-group-item:hover, span p {
  transition: 200ms;
  color: black !important;
}

/*@media only screen and (max-width: 1000px) {*/
/*  .search-bar {*/
/*    !*width: 20% !important;*!*/
/*    display: none !important;*/
/*  }*/
/*}*/

.search-input {
  border-color: #c3baba !important;
}

#filterInput:focus {
  border-color: #c3baba !important;
}

h4 {
  margin-bottom: 0 !important;
  padding: 6px;
}

.cari-group-table button {
  border: none !important;
}

.transfer-icon {
  background-color: #1F8A70 !important;
}

.trash-icon {
  background-color: #F55050 !important;
}

.transfer-modal .form-group {
  width: 100% !important;
  float: right !important;
}

.msl-multi-select {
  width: 108% !important;
}

#firma-unvan-yetkili {
  margin-top: 2px !important;
  padding: 0 !important;
  margin-bottom: 0 !important;
}

#bakiye-badge {
  font-size: 14px !important;
}

#stoksuz-satis-label {
  text-align: center;
}
</style>
